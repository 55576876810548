<template>
<v-container style="margin-top:60px;margin-bottom: 75px"  fluid>
  <!-- <v-img src="img/flash.png" style="position:absolute;top:-0px;left:0;right:0; margin:auto;z-index:1000;width:99%;"></v-img> -->
  <!-- <v-card class="rounded-lg" elevation="10" style="position:absolute;top:-0px;left:0;right:0; margin:auto;z-index:1000;width:99%;">
    <v-card-title><center><v-img src="img/flash.png" style="position:absolute;top:-0px;left:0;right:0; margin:auto;z-index:1000;width:99%;"></v-img></center></v-card-title>
  </v-card> -->

  <v-row>
        <v-col   cols="12" style="display: flex;    flex-direction: column;    align-items: center;    align-self: center;">
          <center><v-img src="img/robot_head_main.png" style="max-width: 106px;"></v-img></center></v-col>
       
  </v-row>

  <v-row>
        <v-col   cols="12" style="display: flex;    flex-direction: row;    align-items: center;    align-self: center;justify-content: center;">
          <span style="font-size:50px;">{{ points }}</span><br>
            <v-img src="img/ico_points.png" style="max-width: 51px;margin-left: 10px;"></v-img>
          </v-col>
       
  </v-row>



  <v-row justify="center" no-gutters style="    margin-top: 25px;    margin-bottom: 15px;" @click="$router.push({ name: 'LPR_STAT' })" >
    <v-col   cols="12" >
      <v-card style="width: 100%;border-radius: 20px; min-height: 20px;" class="transparent_to_background">
        <div style="padding: 15px;margin-top: -10px;margin-bottom: 0px;">
        <span style="padding: 15px;margin-top: 10px;" class="header_task">Статистика</span>
        </div>


      </v-card>
    </v-col>
  </v-row>






  <v-row justify="center" no-gutters style="    margin-top: 10px;    margin-bottom: 15px;" id="news_block">
    <v-col   cols="12" >
      <v-card style="width: 100%;border-radius: 20px; min-height: 250px;padding: 10px;" class="transparent_to_background">
        <div style="padding: 15px;margin-top: -10px;margin-bottom: 0px;">
        <span  class="header_task">Новини</span>
      </div>
        <v-card class="brand_card" style="margin-bottom: 25px;    padding: 10px;"
        elevation="10" v-for="(content, i) in news" :key="i">
        <v-row align="center"
        justify="center" @click="$router.push({ name: 'News' })">
          <v-col cols="2"><v-img src="/img/n_ico.png" class="ma-auto" style="max-width: 45px;padding-left: 5px;margin-left: 5px;" ></v-img>
          </v-col>
          <v-col cols="10">{{ content.header }}</v-col>
        </v-row>
        
      </v-card>


    </v-card>
    </v-col>
  </v-row> 

  <v-row justify="center" no-gutters style="    margin-top: 25px;    margin-bottom: 15px;">
    <v-col   cols="12" >
      <v-card style="width: 100%;border-radius: 20px; min-height: 250px;" class="transparent_to_background">
        <div style="padding: 15px;margin-top: -10px;margin-bottom: 0px;">
        <span style="padding: 15px;margin-top: 10px;" class="header_task">Інформація</span>
        </div>



        <v-row>
          <v-col cols="12" style="display: flex;justify-content: space-around;">
            <div @click="$router.push({ name: 'C_info' })"  class="simple_card" style="    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;font-size: 19px;white-space: nowrap;">
              <div><img src="/img/main_ico_company.png" style="height:84px;width: auto;"> </div>
              <div>Про компанію</div>
            </div>

            <div  @click="$router.push({ name: 'News_pg' })" class="simple_card" style="    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;font-size: 19px">
              <div><img src="/img/main_ico_plan.png" style="height:84px;width: auto;"> </div>
              <div>Планограма</div>
            </div>

          </v-col> 
        </v-row>




        
        
          <v-row   justify="center"  style="    margin-left: -5px;    margin-right: -5px;    margin-top: 10px;    margin-bottom: 10px;">
    <v-col   cols="12" >
      <v-card style="width: 100%;border-radius: 20px; min-height: 200px;" class="transparent_to_background">
        <span style="padding: 15px;margin-top: 10px;" class="header_task">Про бренди</span>
        <div class="carousel-wrapper">
          <div class="carousel" ref="slick2">
           
            <v-card  @click=" $router.push({ name:'Info_Brands_Davidoff'})" style="width:200px;height:105px;margin: 9px;" class="carousel-item d-flex align-center justify-center simple_card">
              <v-img src="/img/ico_top/davidoff_black.png" alt="Slide Image" class="responsive-img"       max-width="170px"       max-height="100%"  style="padding-left: 15px;padding-right: 15px"    contain eager ></v-img>
            </v-card>
           <v-card  @click=" $router.push({ name:'Info_Brands_West'})" style="width:200px;height:105px;margin: 9px;" class="carousel-item d-flex align-center justify-center simple_card">
              <v-img src="/img/brand_logo_west.png" alt="Slide Image" class="responsive-img"       max-width="170px"       max-height="100%"  style="padding-left: 15px;padding-right: 15px"    contain eager ></v-img>
            </v-card>
            <!-- <v-card  @click=" $router.push({ name:'Info_Brands_PS'})" style="width:200px;height:105px;margin: 9px;" class="carousel-item d-flex align-center justify-center">
              <v-img src="/img/ico_top/ps_ico_top2.png" alt="Slide Image" class="responsive-img"       max-width="170px"       max-height="100%"  style="padding-left: 15px;padding-right: 15px"    contain eager ></v-img>
            </v-card> -->
           <v-card  @click=" $router.push({ name:'Info_Brands_Jade'})" style="width:200px;height:105px;margin: 9px;" class="carousel-item d-flex align-center justify-center simple_card">
              <v-img src="/img/ico_top/jade_ico_top_new.png" alt="Slide Image" class="responsive-img"       max-width="170px"       max-height="100%"  style="padding-left: 15px;padding-right: 15px"    contain eager ></v-img>
            </v-card>
            <v-card  @click=" $router.push({ name:'Info_Brands_ImperialClassic'})" style="width:200px;height:105px;margin: 9px;" class=" carousel-item d-flex align-center justify-center simple_card">
              <v-img src="/img/ico_top/ic_ico_top.png" alt="Slide Image" class="responsive-img"       max-width="170px"       max-height="100%"  style="padding-left: 15px;padding-right: 15px"    contain eager ></v-img>
            </v-card>
           <v-card  @click=" $router.push({ name:'Info_Brands_Prima'})" style="width:200px;height:105px;margin: 9px;" class="carousel-item d-flex align-center justify-center simple_card">
              <v-img src="/img/ico_top/prima_ico_top.png" alt="Slide Image" class="responsive-img"       max-width="170px"       max-height="100%"  style="padding-left: 15px;padding-right: 15px"    contain eager ></v-img>
            </v-card>
          </div>
        </div>
    </v-card>
    </v-col>
  </v-row>  


          <v-row style="margin-top:-15px ;">
              <v-col cols="12" style="display: flex;/*justify-content: space-around;*/">
                <div @click="$router.push({ name: 'Vazhl_sel' })"  class="simple_card" style="    width: 48%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 12px;font-size: 19px;white-space: nowrap;margin-left: 10px;">
                  <div><img src="/img/main_ico_vaszhl.png" style="height:70px;width: auto;"> </div>
                  <div>Про важливе</div>
                </div>

                <!-- <div  @click="/*$router.push({ name: 'News_pg' })*/" class="simple_card" style="    width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 12px;font-size: 19px">
                  <div><img src="/img/main_ico_instr.png" style="height:70px;width: auto;"> </div>
                  <div>Інструкція</div>
                </div> -->

              </v-col> 
            </v-row>



    </v-card>
    </v-col>
  </v-row>
  

  <v-container v-if="1==2">

    <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_m_profile.png"
         
          @click.native="$router.push({name:'Profile'})"
          >Мій профіль
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>
    <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_m_balance.png"
         
          @click.native="$router.push({name:'Balance'})"
          >Мої бали
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>

    <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_m_stat.png"
         
          @click.native="$router.push({name:'LPR_STAT'})"
          >Статистика
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>

    <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_m_emo.png"
          
           @click.native="$router.push({name:'Withdraw_sel'})"
          >Емоції
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>
    <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_m_info.png"
         
          @click.native="$router.push({name:'News_sel'})"
          >iTeam новини
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>
    <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/gl_ico.png"
          @click.native="$router.push({name:'Info_sel'})"
          >Корисна інформація
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>

    <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_m_news.png"
          @click.native="$router.push({name:'TPQ'})/*$emit('pushe', {header:'Повідомлення',text:'Функція недоступна, додаток працює у обмеженому режимі'});*/" 
          >Комунікація
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>

    <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_m_exit.png" 
           
          @click.native="logout"
          >
          Вийти
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>
    </v-container>







<v-dialog
        v-model="dialog_netw_confirm"
         style="z-index:50000;"
      >
      <v-card class="rounded-lg" style="" elevation="10">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px;text-align:center;justify-content: center;">Мережевий iTeam</v-card-title>
          <v-card-text>
             <v-row> <v-col>
              <p><b>  Запит очікує підтвердження модератором.</b></p>
              <p>Ви зможете продовжити реєстрацію та виконувати завдання після підтвердження.</p>
                </v-col>
             </v-row>
          </v-card-text>
          <v-card-actions>
            
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog_netw_confirm = false"
            >
              Закрити
            </v-btn>
          </v-card-actions>
      </v-card>
</v-dialog>

<v-dialog
        v-model="dialog_sku_confirm"
         style="z-index:50000;"
      >
      <v-card class="rounded-lg" style="" elevation="10">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px;text-align:center;justify-content: center;">Ассортимент iTeam</v-card-title>
          <v-card-text>
             <v-row> <v-col>
              <p><b>  Запит очікує підтвердження модератором.</b></p>
              <p>Ви зможете виконувати завдання після підтвердження.</p>
                </v-col>
             </v-row>
          </v-card-text>
          <v-card-actions>
            
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog_sku_confirm = false"
            >
              Закрити
            </v-btn>
          </v-card-actions>
      </v-card>
</v-dialog>

<v-dialog
        v-model="pass_change"
        width="300" style="z-index:50000;"
      >
      <v-card class="rounded-lg" style="" elevation="10">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px;text-align:center;justify-content: center;">Встановіть пароль</v-card-title>
          <v-card-text>
            <!-- <v-row fluid>
             
                <v-col cols="12">
                  <v-text-field
                    v-model="password_old"
                    name="Password1n"
                    label="Cтарий пароль"
                    solo
                    rounded
                    @click:append="show1 = !show1"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                  
                  ></v-text-field>
                </v-col>
               
              </v-row> -->
              <v-row fluid>
                
                <v-col cols="12">
                  <v-text-field
                    v-model="password_new"
                    name="Password1n"
                    label="Новий пароль"
                    solo
                    rounded
                    @click:append="show3 = !show3"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show3 ? 'text' : 'password'"
                  
                  ></v-text-field>
                </v-col>
                
              </v-row>
              <v-row fluid>
               
                <v-col cols="12">
                  <v-text-field
                    v-model="password_new_confirm"
                    name="Password2n"
                    label="Підтвердження паролю"
                    solo
                    rounded
                    @click:append="show2 = !show2"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                  ></v-text-field>
                </v-col>
               
              </v-row>
           
            
          </v-card-text>
          <v-card-actions>
            
            <v-btn
              color="primary"
              text
              @click="pass_set_new"
            >
              Встановити
            </v-btn>
            <!-- <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="pass_change = false"
            >
              Відмінити
            </v-btn> -->
          </v-card-actions>
      </v-card>
</v-dialog>




</v-container>
</template>

<script>
import axios from "axios";
import { API_LOCATION } from "../../config";
import btnsqr from "../components/btnsqr.vue";
//import l_switch from "../components/lang_switch.vue";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import $ from 'jquery';
import 'slick-carousel';


export default {
  watch:{
    

    

  },
  components:{btnsqr},
  computed: {
    points: function () {
      return this.$store.getters.points;
    },
  },
  data: () => ({
    dialog_netw_confirm:false,
    dialog_sku_confirm:false,
    sku_lock:false,
    network_lock:false,
    deleted_lock:false,
    task_pending:0,
    menu_active:true,
    pass_change:false,
    password_old:null,
    password_new:null,
    password_new_confirm:null,
    show1: false,
    show2: false,
    show3: false,
    news:[],
     g:{long:'',lat:'',coord_state:''},
    
  }), 
  methods: {
    
    initializeSlick2() {
      this.$nextTick(() => {
        $(this.$refs.slick2).slick({
      dots: true,
      arrows: false,  // Disable arrow controls
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      //centerMode: true,  // Show part of the next slide
      variableWidth: true, // Allow cards of different widths to appear properly
     
      // eslint-disable-next-line no-unused-vars
      customPaging: function(slider, i) {
        return '<div class="custom-dot custom-dot2"></div>';
      }
    });
      });
    },
    navOnSuccess(position) {
                 console.log('Latitude: '          + position.coords.latitude          + '\n' +
                        'Longitude: '         + position.coords.longitude         + '\n' +
                        'Altitude: '          + position.coords.altitude          + '\n' +
                        'Accuracy: '          + position.coords.accuracy          + '\n' +
                        'Altitude Accuracy: ' + position.coords.altitudeAccuracy  + '\n' +
                        'Heading: '           + position.coords.heading           + '\n' +
                        'Speed: '             + position.coords.speed             + '\n' +
                        'Timestamp: '         + position.timestamp                + '\n');
                        
                        
                        this.g.long = position.coords.longitude
                        this.g.lat = position.coords.latitude
                        this.g.coord_state='success'
                        console.log('long: '+this.g.long+': lat:'+this.g.lat)
                        //alert('long: '+this.g.long+': lat:'+this.g.lat)
                        console.log(this.g);

              },

              navOnError(error) {
                  console.log('NAV_FAIL: code: '    + error.code    + '\n' +
                        'message: ' + error.message + '\n')
              },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    pass_set_new:function(){
            axios
      .post(
        API_LOCATION,
        {
          
          action: "pass_set_new",
          pass_old:this.password_old,
          pass_new1: this.password_new,
          pass_new2: this.password_new_confirm,
          temp:true
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data);
        if(resp.data.pass_set!='ok'&&resp.data.debug!='on')
        {
          alert(this.$t(resp.data.error));
        }
        else
        {
          alert(this.$t('success_change'));
          this.pass_change=false;
          this.logout();

        }
      });


    
  },
    check_prof: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "check_prof"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              if(resp.data==-1)         {
                this.$emit('pushe', {header:'Повідомлення',text:'Заповніть Ваш профіль, вкажіть місто, додатковий телефон та оберіть іконку.'});
              } 
              
        
      });

    },
    get_k_type: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "k_type"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              if(resp.data==9)         {
                this.$router.push("/Asssel");
              } 
              else if(resp.data==10)     {
                 this.$router.push("/Asssel");
              }
        
      });

    },
    get_pending_task: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "pending_task"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              this.task_pending=resp.data;
              console.log('PENDING:'+resp.data);
              
        
      });

    },
    get_pending_network_confirm: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "is_network_pending"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              if(resp.data==1)         {
                this.dialog_netw_confirm=true;
                this.network_lock=true
              }
        
      });

    },
    get_pending_sku_confirm: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "is_sku_pending"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              if(resp.data==1)         {
                this.dialog_sku_confirm=true;
                this.sku_lock=true;
              }
        
      });

    },
    get_pending_sku_change: function(){

    },
    get_pending_reg_complete: function(){

    }
    
  },
  created() {
    axios
        .post(
          API_LOCATION,
          { data: "component_data", action: "get_news_latest", id: this.task_id },
          { headers: { "content-type": "application/x-www-form-urlencoded" } }
        )
        .then((resp) => {
          
          this.news=resp.data;
          console.log('!!!news');
          console.log(this.news);
        });
    this.check_prof();
          // if (navigator.geolocation)
          //     {
          //     navigator.geolocation.getCurrentPosition(this.navOnSuccess, this.navOnError,{enableHighAccuracy:false,maximumAge:Infinity, timeout:60000})
          //      console.log('start_nav_detect')
          //     }
          //     else
          //     {
          //       console.log('geolocation_not_supported')
          //     }

    this.$store.dispatch("profileset");
    
    this.$store.dispatch("page_title_set", "Головна");  
    
    axios
      .post(
        API_LOCATION,
        {
          
          action: "api_check"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              console.log(resp.data);         
        
      });
    this.get_k_type();
    this.get_pending_sku_confirm();
    this.get_pending_network_confirm();

    this.initializeSlick2();







  },
};
</script>


<i18n>
{
  "ua": {
    "wrong_old":"Помилковий старий пароль",
"pass not match":"Паролі не співпадають",
    "pass insecure":"Довжина паролю має бути від 6 символів",
    "success_change":"Пароль успішно змінено, увійдіть до додатку з новим паролем",
"close":"Закрити"

  }
}
</i18n>



<style >
.carousel-item {
  padding: 0 10px;
  width: 300px; /* Ensure each card has a fixed width */
}

.slick-dots {
  bottom: -10px; /* Adjust the position of dots */
}

.custom-dot2 {
  bottom: -5px; /* Adjust the position of dots */
}

.slick-dots li button {
  display: none; /* Hide the default buttons inside the dots */
}

.slick-dots li {
  margin: 0 5px;
}

.slick-dots li::before {
  content: '';
}

.custom-dot {
  width: 12px;
  height: 12px;
  background-color: orange; /* Custom orange dots */
  border-radius: 50%;
  cursor: pointer;
}

.slick-dots .slick-active .custom-dot {
  background-color: darkorange; /* Active dot styling */
}
.responsive-img {
  object-fit: contain; /* Ensures the whole image is visible */
}
.slick-slide{
  margin-right: 9px;
}
</style>